import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-list',
  templateUrl: './dialog-list.component.html',
  styleUrl: './dialog-list.component.scss',
})
export class DialogListComponent {
  @Input() list!: any[];
  @Input() title!: string;
  @Input() show = false;
  @Input({ required: true }) valueKey!: string | ((element: any) => string);
  @Input() secoundValue: string | null = null;

  @Output() onHide = new EventEmitter<void>();

  notifyHide() {
    this.onHide.emit();
  }

  getValue(element: any): string {
    if (typeof this.valueKey === 'function') {
      return this.valueKey(element);
    }
    return element[this.valueKey];
  }
}
