export enum DICTIONARY_API_ENDPOINTS {
  Collective_Packaging_List = 'item-packages/list',
  Collective_Packaging_List_Export = '/item-packages/export',
  Order_Types_List = 'order-types/list',
  Order_Types_List_Export = 'order-types/export',
  Clients_Groups = 'client-groups/',
  Clients_Groups_List = Clients_Groups + 'list',
  Clients_Groups_List_Export = Clients_Groups + 'export',
  Clients_Groups_Element = Clients_Groups + 'get/',
  Clients_Groups_Element_Add = Clients_Groups + 'store',
  Clients_Groups_Element_Edit = Clients_Groups + 'edit/',
  Clients_Groups_Delete = Clients_Groups + 'delete',
  Item_Categories = 'item-categories/',
  Item_Categories_List = Item_Categories + 'list',
  Item_Categories_List_Export = Item_Categories + 'export',
  Item_Categories_Element = Item_Categories + 'get/',
  Item_Categories_Element_Add = Item_Categories + 'store',
  Item_Categories_Element_Edit = Item_Categories + 'edit/',
  Item_Categories_Delete = Item_Categories + 'delete',
  Item_Groups = 'item-groups/',
  Item_Groups_List = Item_Groups + 'list',
  Item_Groups_List_Export = Item_Groups + 'export',
  Item_Groups_Element = Item_Groups + 'get/',
  Item_Groups_Element_Add = Item_Groups + 'store',
  Item_Groups_Element_Edit = Item_Groups + 'edit/',
  Item_Groups_Delete = Item_Groups + 'delete',
  Order_Statuses = 'order-statuses/',
  Order_Statuses_List = Order_Statuses + 'list',
  Order_Statuses_List_Export = Order_Statuses + 'export',
  Packaging = 'packages/',
  Packaging_Types_List = Packaging + 'type-list',
  Packaging_Types_List_Export = Packaging + 'export',
  Packaging_List = Packaging + 'list',
  Packaging_List_Export = Packaging + 'export',
  Packaging_Get_Element = Packaging + 'get/',
  Packaging_Delete = Packaging + 'delete',
  Packaging_Edit = Packaging + 'edit',
  Packaging_Add = Packaging + 'store',
  Packaging_Groups = 'package-groups/',
  Packaging_Groups_List = Packaging_Groups + 'list',
  Packaging_Groups_List_Export = Packaging_Groups + 'export',
  Packaging_Group_Delete = Packaging_Groups + 'delete',
  Packaging_Group_Edit = Packaging_Groups + 'edit/',
  Packaging_Group_Add = Packaging_Groups + 'store',
  Packaging_Group_Get_Element = Packaging_Groups + 'get/',
  Production_Statuses = 'production-statuses/',
  Production_Statuses_List = Production_Statuses + 'list',
  Production_Statuses_List_Export = Production_Statuses + 'export',
  Production_Statuses_Get_Element = Production_Statuses + 'get/',
  Production_Statuses_Delete = Production_Statuses + 'delete',
  Production_Statuses_Edit = Production_Statuses + 'edit/',
  Production_Statuses_Add = Production_Statuses + 'store',
  Production_Stands_Types_list = 'production-stands/type-list',
}
