import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import {isObservable} from "rxjs";

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrl: './dropdown-input.component.scss',
})
export class DropdownInputComponent  {
  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) options!: [name: string, id: string | number];
  @Input({ required: true }) inputId!: string;
  @Input({ required: true }) label!: string;
  @Input() placeholder: string = 'Wybierz opcję';
  @Input() filterFunction!: any;
  @Output('onFilter') onFilter: EventEmitter<any> = new EventEmitter<any>();

  onFilterFunction(event: any) {
    if (!isObservable(this.filterFunction(event.filter))) {
      return;
    }

    this.filterFunction(event.filter).subscribe((response: any) => {
      this.options = response.data;
    });



    //this.onFilter.emit(event);
  }


}
