import { Component, Input } from '@angular/core';
import { IDocumentDetailsPhoto } from '../../../warehouse/models/documentDetailsPhoto';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss',
})
export class GalleryComponent {
  @Input({ required: true }) dataPhotos!: IDocumentDetailsPhoto[];
}
