<div class="subpage-wrapper">
  <p-table
    *ngIf="tableData"
    #dt
    [paginator]="true"
    [rows]="rows"
    [value]="tableData"
    [columns]="columnsConfig"
    [tableStyle]="{ 'min-width': '10rem' }"
    [showCurrentPageReport]="true"
    currentPageReportTemplate=" {first} - {last} z {totalRecords} elementów"
    [rowsPerPageOptions]="[10, 15, 20, 50, 100, 200]"
    [lazy]="true"
    (onLazyLoad)="loadWarehousesLazy($any($event))"
    [totalRecords]="totalRecords"
    selectionMode="multiple"
    [(selection)]="selectedRows"
    styleClass="p-datatable-gridlines"
    [resizableColumns]="true"
    stateStorage="local"
    [stateKey]="statekey"
  >
    <ng-template pTemplate="caption">
      <app-filter-element
        (clearFilters)="clear(dt)"
        (changeSorting)="handleFilterChange($event)"
        [filtersConfig]="filtersConfig"
        [isFilterSidebarOpen]="isFilterSidebarOpen"
        (closeFilterSidebar)="toggleFilterSidebar()"
        (onInit)="initFiltersOptions($event)"
      ></app-filter-element>

      <div class="table-btns-wrapper mb-2">
        <div class="primary-btns-wrapper">
          <p-button
            *ngIf="editableByUser"
            type="button"
            class="mr-2"
            icon="pi pi-plus"
            label="Dodaj"
            severity="success"
            [routerLink]="[addNewLink]"
            [outlined]="true"
          ></p-button>
          <p-button
            type="button"
            class="mr-2"
            icon="pi pi-filter"
            label="Filtry ({{ activeFilters }})"
            severity="primary"
            (click)="toggleFilterSidebar()"
            [outlined]="true"
          ></p-button>
        </div>
        <div class="secondary-btns-wrapper">
          <div class="flex" *ngIf="selectedRows.length != 0">
            <p-button
              label="Usuń zaznaczone"
              severity="danger"
              class="ml-2"
              (click)="emitSelected($event)"
              [outlined]="true"
            ></p-button>
            <p-button
              *ngIf="otherActionsOnSelected"
              [label]="otherActionsBtnText"
              severity="info"
              class="ml-2"
              (click)="emitSelectedWithOtherAction($event)"
              [outlined]="true"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem" *ngIf="editableByUser">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th pSortableColumn="id" f>ID <p-sortIcon field="id" /></th>
        <ng-container *ngFor="let col of columnsConfig">
          <th
            pResizableColumn
            *ngIf="!(col.field === '_actions' && !editableByUser)"
            [pSortableColumn]="col.sortable ? col.field : undefined"
          >
            {{ col.header }}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-dataElement let-i="rowIndex">
      <tr
        (click)="clickable ? redirectTo(dataElement.id, $event) : null"
        [ngClass]="{ clicable: clickable }"
      >
        <td *ngIf="editableByUser" class="actions-cell">
          <p-tableCheckbox [value]="dataElement"></p-tableCheckbox>
        </td>
        <td>{{ dataElement.id }}</td>
        <ng-container *ngFor="let col of columnsConfig">
          <ng-container [ngSwitch]="col.type">
            <td *ngSwitchCase="'text'">
              <app-text-table-field
                [value]="dataElement[col.field]"
              ></app-text-table-field>
            </td>
            <td *ngSwitchCase="'text-notification'">
              <app-text-with-notification-table-field
                [mainField]="col.field"
                [element]="dataElement"
                [fields]="col.notifications!"
              ></app-text-with-notification-table-field>
            </td>
            <td *ngSwitchCase="'decimal'">
              <app-decimal-table-field
                [value]="dataElement[col.field]"
              ></app-decimal-table-field>
            </td>

            <td *ngSwitchCase="'decimal-color'">
              <app-decimal-color-table-field
                [value]="dataElement[col.field]"
              ></app-decimal-color-table-field>
            </td>
            <td *ngSwitchCase="'icon'">
              <app-icon-table-field
                [value]="dataElement[col.field]"
              ></app-icon-table-field>
            </td>
            <td *ngSwitchCase="'icon-status'">
              <app-icon-status-field
                [value]="dataElement[col.field]"
              ></app-icon-status-field>
            </td>
            <td *ngSwitchCase="'progress'">
              <app-progress [value]="dataElement[col.field]"></app-progress>
            </td>

            <td *ngSwitchCase="'label'">
              <app-label-table-field
                severity="info"
                [value]="dataElement[col.field]"
              ></app-label-table-field>
            </td>

            <td *ngSwitchCase="'boolean'">
              <app-boolean-table-field
                [value]="dataElement[col.field]"
              ></app-boolean-table-field>
            </td>

            <td *ngSwitchCase="'url'">
              <app-url-table-field
                [value]="dataElement[col.field]"
              ></app-url-table-field>
            </td>
            <ng-container *ngIf="editableByUser">
              <td *ngSwitchCase="'actions'" class="actions-cell">
                <app-actions-table-field
                  [id]="dataElement.id"
                  [actions]="dataElement[col.field]"
                  [editLink]="editLink"
                  (onClick)="
                    handleClickActions(
                      $event.target,
                      $event.id,
                      $event.actionType
                    )
                  "
                ></app-actions-table-field>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="columnsConfig.length + 2" class="p-5 text-center">
          Brak Elementów
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft"> </ng-template>
    <ng-template pTemplate="paginatorright">
      <p-button
        type="button"
        icon="pi pi-file-excel"
        size="large"
        (onClick)="onExport()"
        [rounded]="true"
        [text]="true"
        [pTooltip]="tooltipContentExport"
        tooltipPosition="left"
        *ngIf="withExportToExcell"
      ></p-button>
    </ng-template>
  </p-table>
</div>
<ng-template #tooltipContentExport>
  <div class="flex align-items-center justify-content-center">
    <p>Eksport do Excel</p>
  </div>
</ng-template>
