import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, Subscription, switchMap } from 'rxjs';
import { PredefinedDataService } from '../../../../core/services/predefined-data.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

interface Item {
  id: number;
  name: string;
}

interface ICustomRecipeElement {
  id?: number;
  name: string;
  component_item_id: number;
  qty: number;
  note: string;
}
@Component({
  selector: 'app-recipe-input',
  templateUrl: './recipe-input.component.html',
  styleUrl: './recipe-input.component.scss',
})
export class RecipeInputComponent {
  private searchSubscription = new Subscription();

  @Input({ required: true }) controlName!: FormControl<any>;
  @Input({ required: true }) passedCurrentElements!: ICustomRecipeElement[];

  searchTerms = new Subject<string>();
  selectedOption: Item | null = null;
  isSelectedOptionInRecipe = false;
  currentSelectedToEdit: ICustomRecipeElement | null = null;
  suggestions: Item[] = [];
  dialogQtyValue: number | undefined;
  editDialogQtyValue: number | undefined;
  dialogTextAreaValue = '';
  editDialogTextAreaValue = '';

  addDialogVisible: boolean = false;
  editDialogVisible: boolean = false;
  currentRecipeElements: ICustomRecipeElement[] = [];

  constructor(private predefinedDataService: PredefinedDataService) {}

  ngOnInit(): void {
    if (this.passedCurrentElements.length) {
      this.currentRecipeElements = this.passedCurrentElements;
      this.setControlValue();
    }
    this.searchSubscription = this.searchTerms
      .pipe(
        debounceTime(300),
        switchMap((term) => this.predefinedDataService.getResourcesList(term))
      )
      .subscribe({
        next: (response) => {
          this.suggestions = response.data;
        },
        error: () => console.error('Błąd przy pobieraniu zasobów'),
      });
  }
  showAddDialog() {
    this.addDialogVisible = true;
  }

  closeAddDialog() {
    this.addDialogVisible = false;
    this.resetAddDialogInputs();
  }
  search(event: AutoCompleteCompleteEvent) {
    this.searchTerms.next(event.query);
  }

  onSelect() {
    if (
      this.currentRecipeElements.some(
        (element) => element.component_item_id === this.selectedOption?.id
      )
    ) {
      this.isSelectedOptionInRecipe = true;
    } else {
      this.isSelectedOptionInRecipe = false;
    }
  }

  addElementToRecipe() {
    const materialToAdd = {
      name: this.selectedOption!.name,
      component_item_id: this.selectedOption!.id,
      qty: this.dialogQtyValue!,
      note: this.dialogTextAreaValue,
    };

    this.currentRecipeElements = [...this.currentRecipeElements, materialToAdd];
    this.setControlValue();
    this.addDialogVisible = false;
    this.resetAddDialogInputs();
  }

  setControlValue() {
    const value = this.currentRecipeElements.map((element) => {
      const baseValue = {
        id: element.id ?? null,
        component_item_id: element.component_item_id,
        qty: element.qty,
        note: element.note ?? '',
      };

      return baseValue;
    });
    this.controlName.setValue(value);
  }

  showEditDialog(element: ICustomRecipeElement) {
    this.currentSelectedToEdit = element;
    this.editDialogQtyValue = element.qty;
    this.editDialogTextAreaValue = element.note ?? '';
    this.editDialogVisible = true;
  }
  editMaterial() {
    this.currentRecipeElements = this.currentRecipeElements.map((element) => {
      if (
        element.component_item_id ===
        this.currentSelectedToEdit?.component_item_id
      ) {
        return {
          ...element,
          qty: this.editDialogQtyValue!,
          note: this.editDialogTextAreaValue,
        };
      }
      return element;
    });
    this.setControlValue();
    this.editDialogVisible = false;
    this.resetEditDialogValues();
  }
  deleteMaterial(id: number) {
    this.currentRecipeElements = this.currentRecipeElements.filter(
      (element) => element.component_item_id !== id
    );
    this.setControlValue();
  }
  resetAddDialogInputs() {
    this.selectedOption = null;
    this.dialogQtyValue = undefined;
    this.dialogTextAreaValue = '';
  }
  resetEditDialogValues() {
    this.currentSelectedToEdit = null;
    this.editDialogQtyValue = undefined;
    this.editDialogTextAreaValue = '';
  }
  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }
}
