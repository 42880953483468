import { Component, Input, OnInit } from '@angular/core';

// @TODO: przekazywać dane dynamicznie

@Component({
  selector: 'app-expansion-table',
  templateUrl: './expansion-table.component.html',
  styleUrl: './expansion-table.component.scss',
})
export class ExpansionTableComponent implements OnInit {
  @Input({ required: true }) data!: any[];

  expandedRows: { [key: string]: boolean } = {};
  expandedSecoundLevelRows: { [key: string]: boolean } = {};
  calculatedRowSpan!: number;
  columnsConfig: any;
  dataKeys = {
    secoundLevel: 'items',
  };
  constructor() {}

  ngOnInit(): void {
    this.columnsConfig = {
      levelNames: {
        first: 'Palety',
        secound: 'Towary',
      },
      firstLevel: [
        {
          field: 'palletBarcode',
          header: 'Kod',
          sortable: false,
          type: 'text',
        },
        {
          field: 'isRealized',
          header: 'Wysłana',
          sortable: false,
          type: 'boolean',
        },
        {
          field: 'realizedDate',
          header: 'Data wysyłki',
          sortable: false,
          type: 'text',
        },

        {
          field: 'addDate',
          header: 'Data dodania',
          sortable: false,
          type: 'text',
        },
      ],
      secoundLevel: [
        {
          field: 'itemName',
          header: 'Nazwa',
          sortable: false,
          type: 'text',
        },
        {
          field: 'itemUnitName',
          header: 'J.M',
          sortable: false,
          type: 'text',
        },
        {
          field: 'ItemQtyPacked',
          header: 'Ilość spakowana',
          sortable: false,
          type: 'decimal',
        },
        {
          field: 'itemBarcode',
          header: 'Kod',
          sortable: false,
          type: 'text',
        },
        {
          field: 'addDate',
          header: 'Data Dodania',
          sortable: false,
          type: 'text',
        },
      ],
    };
    // for expanded tables, calculate first table columns for 100% widht
    this.calculatedRowSpan = this.columnsConfig.secoundLevel.length;
  }

  isPalletsVisible(row: any) {
    return this.expandedRows[row.temporaryId];
  }

  toggleRow(row: any) {
    this.expandedRows[row.temporaryId] = !this.expandedRows[row.temporaryId];
  }

  isItemsVisible(pallet: any) {
    return this.expandedSecoundLevelRows[pallet.temporaryId];
  }

  togglePalletRow(pallet: any) {
    this.expandedSecoundLevelRows[pallet.temporaryId] =
      !this.expandedSecoundLevelRows[pallet.temporaryId];
  }
}
