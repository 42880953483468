import { Component, Input } from '@angular/core';
import { IColumnConfig } from '../../../core/models/columnsConfig';

@Component({
  selector: 'app-table-simplified',
  templateUrl: './table-simplified.component.html',
  styleUrl: './table-simplified.component.scss',
})
export class TableSimplifiedComponent {
  @Input({ required: true }) data!: any[];
  @Input({ required: true }) columnsConfig!: IColumnConfig[];
}
