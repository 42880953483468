<div class="form-input flex flex-column gap-2">
  <label [for]="inputId">{{ label }}</label>
  <p-dropdown
    [id]="inputId"
    [formControl]="controlName"
    [options]="options"
    optionLabel="name"
    optionValue="id"
    [placeholder]="placeholder"
    [filter]="true"
    (onFilter)="onFilterFunction($event)"
  ></p-dropdown>
</div>
